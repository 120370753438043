import React, { FC, memo } from 'react'

import { themesKey } from '../../theme'
import MainLayout from '../../layouts/MainLayout'
import Header, { HeaderProps } from '../../components/Header'
import Footer, { FooterProps } from '../../components/Footer'
import { LinkProps } from '../../components/Link'
import Icon, { Icons } from '../../components/Icon'

import * as SC from './styled'

export type LegalTemplateProps = {
  onButtonThemeClick?: (theme: themesKey) => void
  headerProps?: HeaderProps
  footerProps?: FooterProps
  backButtonProps?: LinkProps
  title?: string
  text?: string
  containerRef?: React.RefObject<HTMLDivElement>
}

const LegalTemplate: FC<LegalTemplateProps> = ({
  headerProps,
  footerProps,
  title,
  text,
  backButtonProps,
  containerRef,
}) => (
  <MainLayout
    header={headerProps ? <Header {...headerProps} /> : null}
    footer={footerProps ? <Footer {...footerProps} /> : null}
  >
    <SC.HomeTemplate ref={containerRef}>
      <SC.Wrapper>
        {backButtonProps && (
          <SC.BackButton {...backButtonProps}>
            <Icon icon={Icons.chevronLeft} />
            {backButtonProps.children}
          </SC.BackButton>
        )}
        {title && <SC.Title>{title}</SC.Title>}
        {text && <SC.Text>{text}</SC.Text>}
      </SC.Wrapper>
    </SC.HomeTemplate>
  </MainLayout>
)

export default memo(LegalTemplate)
