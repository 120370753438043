import React, { FC, memo } from 'react'

import { themesKey } from '../../theme'
import MainLayout from '../../layouts/MainLayout'
import Header, { HeaderProps } from '../../components/Header'
import Footer, { FooterProps } from '../../components/Footer'
import Countdown, { CountdownProps } from '../../components/Countdown'
import { PlayerProps } from '../../components/Player'

import * as SC from './styled'

export type HomepageTemplateProps = {
  onButtonThemeClick?: (theme: themesKey) => void
  headerProps?: HeaderProps
  footerProps?: FooterProps
  countdownProps?: CountdownProps
  title?: string
  text?: string
  playerProps?: PlayerProps
  containerRef?: React.RefObject<HTMLDivElement>
}

const HomepageTemplate: FC<HomepageTemplateProps> = ({
  headerProps,
  footerProps,
  countdownProps,
  title,
  text,
  playerProps,
  containerRef,
}) => (
  <MainLayout
    header={headerProps ? <Header {...headerProps} /> : null}
    footer={footerProps ? <Footer {...footerProps} /> : null}
  >
    <SC.HomeTemplate ref={containerRef}>
      {title && <SC.Title>{title}</SC.Title>}
      {countdownProps && <Countdown {...countdownProps} />}
      {playerProps && <SC.StyledPlayer {...playerProps} />}
      {text && <SC.Text>{text}</SC.Text>}
    </SC.HomeTemplate>
  </MainLayout>
)

export default memo(HomepageTemplate)
