import { declareFont, defineFont, Font, FontWeights } from '../utils/FontUtils'

enum FontKeys {
  BBCond = 'BBCond',
  BBCondBold = 'BBCondBold',
  BBRegular = 'BBRegular',
}

export const declarations: { [key in FontKeys]: Font } = {
  BBCond: {
    basename: 'BB-Cond',
    fontFamily: 'BB-Cond',
    fontWeight: FontWeights.regular,
    fallback: 'Arial, Helvetica, sans-serif',
  },
  BBCondBold: {
    basename: 'BB-CondBold',
    fontFamily: 'BB-CondBold',
    fontWeight: FontWeights.regular,
    fallback: 'Arial, Helvetica, sans-serif',
  },
  BBRegular: {
    basename: 'BB-Regular',
    fontFamily: 'BB-Regular',
    fontWeight: FontWeights.regular,
    fallback: 'Arial, Helvetica, sans-serif',
  },
}

export const fontsFaces = (): string => Object.values(declarations).map(declareFont).join(' ')

const fonts: { [key in FontKeys]: string } = {
  BBCond: defineFont(declarations.BBCond),
  BBCondBold: defineFont(declarations.BBCondBold),
  BBRegular: defineFont(declarations.BBRegular),
}

export default fonts
