import { createGlobalStyle } from 'styled-components'

import { fontsFaces } from './fonts'

export const GlobalStyles = createGlobalStyle`
  ${fontsFaces()}
  * {
    box-sizing: border-box;
    &:before, &:after {
      box-sizing: border-box;
    }
  }
  html {
    font-size: 62.5%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  body {
    padding: 0;
    margin: 0;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  #___gatsby, #gatsby-focus-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  p {
    margin: 0;
    padding: 0;
  }
  a {
    text-decoration: none;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
  img {
    max-width: 100%;
    height: auto;
    vertical-align: middle
  }
  button {
    padding: 0;
    border: 0;
  }  
  input, select, textarea, button {
    &:focus:not([keyboard-focus]) {
      outline-width: 0;
    }
  }
  strong {
    font-weight: bold;
  }
  input::-ms-clear {
    display: none;
  }
`
