import React, { FC, memo, useRef } from 'react'
import { graphql, PageProps } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { useI18next } from 'gatsby-plugin-react-i18next'

import App from '../../App'
import { useHeader } from '../../relay/common/useHeader'
import { useFooter } from '../../relay/common/useFooter'
import LegalTemplate, { LegalTemplateProps } from '../../templates/Legal'
import router from '../../router'
import routes from '../../router/routes'
import { useSeo } from '../../hooks/useSeo'
import Seo from '../../components/Seo'

const HomepageContainer: FC<PageProps> = () => {
  const { t } = useTranslation()
  const { language } = useI18next()
  const headerProps = useHeader()
  const footerProps = useFooter()
  const containerRef = useRef<HTMLDivElement>(null)

  const props: LegalTemplateProps = {
    headerProps,
    footerProps,
    containerRef,
    backButtonProps: {
      link: router(routes.home.path, { lang: language }),
      target: '_self',
      children: t('legal.back'),
    },
    title: t('legal.title'),
    text: t('legal.text'),
  }

  return (
    <App>
      <LegalTemplate {...props} />
    </App>
  )
}

export default memo(HomepageContainer)

export const Head = ({ location, params }: any) => {
  const seo = useSeo({ pathname: location.pathname, lang: params?.language })
  return <Seo {...seo} />
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
