import styled from 'styled-components'

import Html from '../../components/Html'
import Link from '../../components/Link'

export const HomeTemplate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  transition: opacity 0.5s;
  color: ${({ theme }) => theme.colors.pureBlack};
  flex-grow: 1;
  height: 100%;
  padding: 4.8rem 3.6rem;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;
  max-width: 46.4rem;
  width: 100%;
`

export const BackButton = styled(Link)`
  ${({ theme }) => theme.textStyles.text}
  display: flex;
  gap: 0.5rem;
  & svg {
    width: 1.6rem;
    height: 1.6rem;
  }
  align-self: flex-start;
`

export const Title = styled(Html)`
  ${({ theme }) => theme.textStyles.textXl}
  text-align: center;
`

export const Text = styled(Html)`
  ${({ theme }) => theme.textStyles.text}
  max-width: 46.4rem;
  h2 {
    ${({ theme }) => theme.textStyles.textBold};
    letter-spacing: 1px;
  }
  h3 {
    ${({ theme }) => theme.textStyles.textBold};
    letter-spacing: 1px;
    margin-left: 1rem;
  }

  ul {
    margin-top: 0;
    margin-bottom: 2rem;
  }

  a {
    color: ${({ theme }) => theme.colors.pureBlack};
  }
`
