import React, { FC } from 'react'
import { Player as Lottie } from '@lottiefiles/react-lottie-player'
import { IPlayerProps } from '@lottiefiles/react-lottie-player/src/Player'

import * as SC from './styled'

export type PlayerProps = {
  className?: string
  onClick?: () => void
} & IPlayerProps

const Player: FC<PlayerProps> = ({ className, onClick, ...lottieProps }) => {
  return (
    <SC.Container className={className} onClick={onClick}>
      <Lottie {...lottieProps} />
    </SC.Container>
  )
}

export default Player
