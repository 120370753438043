import styled from 'styled-components'

export const Layout = styled.div`
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`
export const Main = styled.main`
  flex-grow: 1;
`
