import styled, { css } from 'styled-components'

import Icon from '../Icon'
import Link from '../Link'

export const Container = styled.div`
  position: relative;
`

export const Others = styled.div<{ $isVisible?: boolean }>`
  position: absolute;
  top: 100%;
  padding-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;

  ${({ $isVisible }) =>
    $isVisible &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`

export const Item = styled(Link)`
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  ${(props) => props.theme.textStyles.text}
`

export const Arrow = styled(Icon)`
  width: 1.6rem;
  height: 1.6rem;
`

export const Selected = styled.span`
  display: flex;
  gap: 0.7rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  cursor: pointer;
  ${(props) => props.theme.textStyles.text}
`
