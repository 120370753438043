import React, { FC, memo } from 'react'

import { themesKey } from '../../theme'
import MainLayout from '../../layouts/MainLayout'
import Header, { HeaderProps } from '../../components/Header'
import Footer, { FooterProps } from '../../components/Footer'
import ActionButton, { ActionButtonProps } from '../../components/ActionButton'

import * as SC from './styled'

export type NotFoundTemplateProps = {
  onButtonThemeClick?: (theme: themesKey) => void
  headerProps?: HeaderProps
  footerProps?: FooterProps
  title?: string
  text?: string
  action?: ActionButtonProps
  containerRef?: React.RefObject<HTMLDivElement>
}

const NotFoundTemplate: FC<NotFoundTemplateProps> = ({
  headerProps,
  footerProps,
  title,
  text,
  containerRef,
  action,
}) => (
  <MainLayout
    header={headerProps ? <Header {...headerProps} /> : null}
    footer={footerProps ? <Footer {...footerProps} /> : null}
  >
    <SC.NotFoundTemplate ref={containerRef}>
      {title && <SC.Title>{title}</SC.Title>}
      {text && <SC.Text>{text}</SC.Text>}
      {action && <ActionButton {...action} />}
    </SC.NotFoundTemplate>
  </MainLayout>
)

export default memo(NotFoundTemplate)
