import { useI18next } from 'gatsby-plugin-react-i18next'
import { useState } from 'react'
import { pathToRegexp } from 'path-to-regexp'

import { HeaderProps } from '../../components/Header'
import routes from '../../router/routes'

export const useHeader = (): HeaderProps => {
  const { languages, language, path } = useI18next()
  const [isOpen, setIsOpen] = useState(false)

  return {
    switchLocale: {
      locales: languages.map((locale) => ({
        name: locale,
        isSelected: locale === language,
        link: {
          route: pathToRegexp(routes.legal.path).test(path) ? routes.legal.path : routes.home.path,
          routeParams: { lang: locale },
        },
      })),
      openSwitchHandler: () => setIsOpen((open) => !open),
      isVisible: isOpen,
    },
  }
}
