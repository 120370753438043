import React, { FC } from 'react'
import cx from 'classnames'

import { LinkProps } from '../Link'
import { ActionButtonProps } from '../ActionButton'

import * as SC from './styled'

export type FooterProps = {
  className?: string
  copyright?: string
  menuEntries?: LinkProps[]
  actions?: ActionButtonProps[]
}

const Footer: FC<FooterProps> = (props) => {
  const { className, menuEntries, copyright, actions } = props

  return (
    <SC.Footer className={cx(className)}>
      {actions && (
        <SC.Actions>
          {actions.map((action, key) => (
            <SC.Action {...action} key={key} />
          ))}
        </SC.Actions>
      )}
      {menuEntries && (
        <SC.Menu>
          {menuEntries.map((menuEntry, key) => (
            <SC.MenuEntry {...menuEntry} key={key} />
          ))}
        </SC.Menu>
      )}
      {copyright && <SC.Copyright>{copyright}</SC.Copyright>}
    </SC.Footer>
  )
}

export default Footer
