import styled from 'styled-components'

import Html from '../../components/Html'
import Player from '../../components/Player'

export const HomeTemplate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  transition: opacity 0.5s;
  color: ${({ theme }) => theme.colors.pureBlack};
  flex-grow: 1;
  height: 100%;
  padding: 4.8rem 3.6rem;
`

export const Title = styled(Html)`
  ${({ theme }) => theme.textStyles.textXl}
  text-align: center;
  text-transform: uppercase;
`

export const StyledPlayer = styled(Player)``

export const Text = styled(Html)`
  ${({ theme }) => theme.textStyles.text}
  max-width: 46.4rem;
  & strong {
    ${({ theme }) => theme.textStyles.textBold}
  }
`
