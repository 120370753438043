import React, { FC } from 'react'
import cx from 'classnames'

import * as SC from './styled'

export type LogoProps = {
  className?: string
}

const Logo: FC<LogoProps> = (props) => {
  const { className } = props

  return <SC.Logo className={cx('Logo', className)} src={'/images/logo.svg'}></SC.Logo>
}

export default Logo
