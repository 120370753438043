import styled from 'styled-components'

export const Html = styled.div`
  ${({ theme }) => theme.textStyles.textMedium}
  color: ${({ theme }) => theme.colors.arapawa};

  & p {
    &:not(:last-child) {
      padding-bottom: 1rem;
    }
  }

  & h1 {
    ${({ theme }) => theme.textStyles.titleH1}
  }
  & h2 {
    ${({ theme }) => theme.textStyles.titleH2}
  }
  & h3 {
    ${({ theme }) => theme.textStyles.titleH3}
  }
  & h4 {
    ${({ theme }) => theme.textStyles.titleH4}
  }
  & a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.ruby};
    &:hover {
      text-decoration: underline;
    }
  }
  & strong,
  & b {
    font-weight: bold;
    font-variation-settings: 'wggt' 700;
  }

  & table {
    width: 100%;

    & tbody tr:first-child {
      & td:nth-child(4) {
        ${({ theme }) => theme.textStyles.textSmallStrong}
        text-align: right;
      }
    }
  }

  & th,
  & td {
    padding: 0.5rem;
  }

  & th {
    ${({ theme }) => theme.textStyles.textSmallStrong}
    text-align: left;
    border-bottom: 0.1rem solid ${({ theme }) => theme.colors.gainsboro};
  }

  & td {
    ${({ theme }) => theme.textStyles.textSmall}
    text-align: left;
    border-bottom: 0.1rem solid ${({ theme }) => theme.colors.gainsboro};
  }
`
