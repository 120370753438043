import { useTranslation } from 'react-i18next'
import { useI18next } from 'gatsby-plugin-react-i18next'
import { pathToRegexp } from 'path-to-regexp'

import { FooterProps } from '../../components/Footer'
import router from '../../router'
import routes from '../../router/routes'

export const useFooter = (): FooterProps => {
  const { language, path } = useI18next()
  const { t } = useTranslation()

  return {
    actions: [
      {
        text: t('footer.actions.balenciaga'),
        link: t('footer.actions.balenciaga.link'),
        target: '_blank',
      },
    ],
    menuEntries: [
      pathToRegexp(routes.legal.path).test(path)
        ? {
            children: t('footer.menu.home'),
            link: router(routes.home.path, { lang: language }),
            target: '_self',
          }
        : {
            children: t('footer.menu.legal'),
            link: router(routes.legal.path, { lang: language }),
            target: '_self',
          },
    ],
    copyright: '© 2024 Balenciaga',
  }
}
