import { GlobalStyles } from './global'
import { default as fonts } from './fonts'
import { default as colors } from './colors'
import { default as sizes } from './sizes'
import { default as textStyles } from './textStyles'
import { default as breakpoints } from './breakpoints'
import * as mixins from './mixins'
import * as stylesHelpers from './stylesHelpers'

export { GlobalStyles, fonts, sizes, breakpoints, mixins, textStyles, stylesHelpers }

export const defaultTheme = {
  colors,
  breakpoints,
  mixins,
  fonts,
  stylesHelpers,
  textStyles,
}

export type CustomTheme = typeof defaultTheme

export enum themesKey {
  defaultTheme = 'defaultTheme',
}

export const themes = {
  [themesKey.defaultTheme]: defaultTheme,
}
