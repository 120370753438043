import './utils/polyfills'

import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from 'styled-components'
import { useSelector } from 'react-redux'

import { GlobalStyles, themes, themesKey } from './theme'
import Seo, { SeoProps } from './components/Seo'
import DefaultHead from './components/DefaultHead'
import { selectors } from './redux'

type AppProps = {
  seo?: SeoProps
  children?: React.ReactNode
}

const App: FC<AppProps> = ({ children, seo }) => {
  const currentTheme = useSelector(selectors.app.theme) as themesKey

  return (
    <>
      <GlobalStyles />
      <Helmet />
      <ThemeProvider theme={themes[currentTheme]}>
        <DefaultHead />
        <Seo {...seo} />
        <Helmet />
        {children}
      </ThemeProvider>
    </>
  )
}

export default App
