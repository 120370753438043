import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import makeStore from '../redux/store'

import BugsnagWrapper from './BugsnagWrapper/BugsnagWrapper'

const WrapWithProvider = ({ element }: any) => {
  const { store, persistor } = makeStore()

  const provider = (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {element}
      </PersistGate>
    </Provider>
  )

  if (BugsnagWrapper) {
    return <BugsnagWrapper>{provider}</BugsnagWrapper>
  }

  return provider
}

export default WrapWithProvider
