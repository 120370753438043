/*
 * This project use mobile first integration
 * Default media is mobile
 */

const sizes = {
  xs: 0,
  sm: 390,
  md: 768,
  lg: 1024,
  xl: 1440,
}

export default sizes
