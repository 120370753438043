import path from 'path'
import { Actions } from 'gatsby'

import type { Route } from '../../router/routes'
import router from '../../router'

export default async (graphql: any, actions: Actions, route: Route) => {
  const { createPage } = actions
  const template = path.resolve('src/containers/Legal/index.tsx')

  const allLocales = await graphql(`
    query {
      locales: allLocale {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
    }
  `)

  const locales: string[] = allLocales?.data?.locales?.edges?.map(
    (edge: any) => edge?.node?.language
  )

  return Promise.all(
    locales?.map((lang: string) =>
      createPage({
        path: router(route.path, { lang }),
        component: template,
        context: {
          lang,
          locales,
        },
      })
    )
  )
}
