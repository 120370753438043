import { all, put, takeLatest, select } from 'redux-saga/effects'

import { actions, selectors } from '..'

import { actionTypes } from './types'

export default class AppSagas {
  static *init() {
    const isInit: ReturnType<typeof selectors.app.isInit> = yield select(selectors.app.isInit)

    if (!isInit) {
      yield put(actions.app.setIsInit({ isInit: true }))
    }
  }

  static *setTheme({ payload }: actionTypes.setTheme) {
    const theme: ReturnType<typeof selectors.app.theme> = yield select(selectors.app.theme)
    console.log('AppSagas - setTheme : ', { payload, theme })
  }

  static *loop() {
    yield all([
      takeLatest(actions.app.init, this.init),
      takeLatest(actions.app.setTheme, this.setTheme),
    ])
  }
}
