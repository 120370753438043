import React, { FC } from 'react'
import { CountdownProps as ReactCountdownProps, zeroPad } from 'react-countdown'

import * as SC from './styled'

export type CountdownProps = {
  //
  isBold?: boolean
  labels: {
    days: string
    hours: string
    minutes: string
    seconds: string
  }
} & ReactCountdownProps

const Countdown: FC<CountdownProps> = (props) => {
  const { labels, isBold, ...countdownProps } = props

  return (
    <SC.Countdown
      {...countdownProps}
      renderer={(rendererProps) => (
        <SC.Content $isBold={isBold}>
          <SC.Slot>
            <SC.Value>{zeroPad(rendererProps.days)}</SC.Value>
            <SC.Label>{labels?.days}</SC.Label>
          </SC.Slot>
          <SC.Slot>
            <SC.Value>{zeroPad(rendererProps.hours)}</SC.Value>
            <SC.Label>{labels?.hours}</SC.Label>
          </SC.Slot>
          <SC.Slot>
            <SC.Value>{zeroPad(rendererProps.minutes)}</SC.Value>
            <SC.Label>{labels?.minutes}</SC.Label>
          </SC.Slot>
          <SC.Slot>
            <SC.Value>{zeroPad(rendererProps.seconds)}</SC.Value>
            <SC.Label>{labels?.seconds}</SC.Label>
          </SC.Slot>
        </SC.Content>
      )}
    ></SC.Countdown>
  )
}

export default Countdown
