import styled, { css } from 'styled-components'
import ReactCountdown from 'react-countdown'

export const Countdown = styled(ReactCountdown)``

export const Content = styled.div<{ $isBold?: boolean }>`
  display: flex;
  gap: 0.6rem;
  min-height: 20rem;
  align-items: center;
  ${({ $isBold, theme }) =>
    $isBold &&
    css`
      ${Label} {
        ${theme.textStyles.textXl};
      }
    `}
`
export const Slot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 5.4rem;
  gap: 0.2rem;
`
export const Value = styled.div`
  ${({ theme }) => theme.textStyles.textXl};
`
export const Label = styled.div`
  ${({ theme }) => theme.textStyles.textSmallBold};
`
