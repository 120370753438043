const colors = {
  pureBlack: '#000',
  pureWhite: '#FFF',
  greyAA: '#AAAAAA',
  blue: '#256fff',
  redError: '#D70000',
  softGrey: '#BEB4AF',
  eggShell: '#FFF2D8',
  greyLighter: '#D8D8D8',
  grey: '#E8E8E8',
  greyMedium: '#AAAAAC',
  greyDarker: '#767676',
}

export default colors
