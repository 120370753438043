import { graphql, PageProps } from 'gatsby'
import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useI18next } from 'gatsby-plugin-react-i18next'

import App from '../App'
import NotFoundTemplate, { NotFoundTemplateProps } from '../templates/NotFound'
import { useHeader } from '../relay/common/useHeader'
import { useFooter } from '../relay/common/useFooter'
import router from '../router'
import routes from '../router/routes'

type NotFoundProps = PageProps<{
  menus: any
}>

const NotFoundPage: FC<NotFoundProps> = () => {
  const { t } = useTranslation()
  const { language } = useI18next()
  const headerProps = useHeader()
  const footerProps = useFooter()

  const props: NotFoundTemplateProps = {
    headerProps,
    footerProps,
    title: t('notFound.title'),
    text: t('notFound.description'),
    action: {
      text: t('notFound.cta'),
      link: router(routes.home.path, { lang: language }),
    },
  }

  return (
    <App>
      <NotFoundTemplate {...props} />
    </App>
  )
}

export default memo(NotFoundPage)

export const query = graphql`
  query NotFound404($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
