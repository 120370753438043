import styled from 'styled-components'

import Logo from '../Logo'
import breakpoints from '../../theme/breakpoints'
import SwitchLocale from '../SwitchLocale'

export const Header = styled.header<{ oneChild?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  width: 100%;
  height: 6rem;
`

export const SLogo = styled(Logo)`
  max-width: 14rem;

  ${breakpoints.up('md')} {
    max-width: 17rem;
  }
`

export const Switch = styled(SwitchLocale)`
  position: absolute;
  right: 2rem;
  top: 2rem;
`
