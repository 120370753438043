import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useSelector } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'

import { app } from '../configuration'

import storage from './storage'
import { reducers } from './index'
import rootSaga from './sagas'

export const rootReducer = combineReducers(reducers)

export type RootState = ReturnType<typeof rootReducer>

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector

const makeStore = (initialState = {}) => {
  const sagaMiddleware = createSagaMiddleware()

  const persistConfig = {
    key: 'root',
    version: 1,
    storage,
  }

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const store = configureStore({
    reducer: persistedReducer,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({
        thunk: false,
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
      sagaMiddleware,
    ],
    devTools: process.env.NODE_ENV !== 'production',
  })

  const persistor = persistStore(store)

  if (!app.persistStoreEnabled) {
    persistor.pause()
    persistor.purge()
  }

  sagaMiddleware.run(rootSaga)

  return { store, persistor }
}

export default makeStore
