import styled from 'styled-components'

import Link from '../Link'
import ActionButton from '../ActionButton'

export const Footer = styled.footer`
  width: 100%;
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
`

export const Actions = styled.div`
  border-top: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.4rem 2rem;
  gap: 2rem;
`

export const Action = styled(ActionButton)``
export const Menu = styled.div`
  border-top: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.4rem 2rem;
  gap: 2rem;
`

export const MenuEntry = styled(Link)`
  ${({ theme }) => theme.textStyles.textSmall};
  text-transform: uppercase;
`

export const Copyright = styled.p`
  padding: 2.4rem 2rem 1rem;
  border-top: 1px solid black;
  ${(props) => props.theme.textStyles.textSmall}
`
