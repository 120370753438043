import React, { FC } from 'react'
import cx from 'classnames'

import { LinkProps } from '../Link'
import { Icons } from '../Icon'

import * as SC from './styled'

export type SwitchLocaleItemProps = {
  name?: string
  locale?: string
  isSelected?: boolean
  link?: LinkProps
}

export type SwitchLocaleProps = {
  className?: string
  locales: SwitchLocaleItemProps[]
  openSwitchHandler: (event: React.MouseEvent<HTMLButtonElement>) => void
  isVisible: boolean
}

const SwitchLocale: FC<SwitchLocaleProps> = (props) => {
  const { className, locales, openSwitchHandler, isVisible } = props

  const selected = locales ? locales.find((locale) => locale.isSelected) : null
  const others = locales ? locales.filter((locale) => locale.isSelected === false) : null

  return (
    <SC.Container className={cx(className)}>
      {selected?.name && (
        <SC.Selected onClick={openSwitchHandler}>
          {selected.name}
          <SC.Arrow icon={Icons.chevronDown} />
        </SC.Selected>
      )}
      {others && (
        <SC.Others $isVisible={isVisible}>
          {others.map((locale, idx) => (
            <SC.Item key={idx} {...locale.link}>
              {locale.name}
            </SC.Item>
          ))}
        </SC.Others>
      )}
    </SC.Container>
  )
}

export default SwitchLocale
