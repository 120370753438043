import fonts from './fonts'

const textStyles = {
  textTiny: `
    ${fonts.BBRegular}
    font-size: 1rem;
    line-height: 1.4;
  `,
  textSmall: `
    ${fonts.BBRegular}
    font-size: 1.2rem;
    line-height: 1.15;
    letter-spacing: 0.03em;
  `,
  textSmallBold: `
    ${fonts.BBCondBold}
    font-size: 1.2rem;
    line-height: 1.15;
    letter-spacing: 0.03em;
  `,
  text: `
    ${fonts.BBRegular}
    font-size: 1.4rem;
    line-height: 1.35;
    letter-spacing: 0.03em;
  `,
  textBold: `
    ${fonts.BBCondBold}
    font-size: 1.4rem;
    line-height: 1.15;
    letter-spacing: 0.03em;
  `,
  textXl: `
    ${fonts.BBCondBold}
    font-size: 2.2rem;
    line-height: 1.1;
    letter-spacing: 0.05em;
  `,
  titleH1: `
    ${fonts.BBCondBold}
    font-size: 3.4rem;
    line-height: 1;
    text-transform: uppercase;
  `,
  titleH2: `
    ${fonts.BBCondBold}
    font-size: 2.5rem;
    line-height: 1;
  `,
}

export default textStyles
