import React, { FC, memo } from 'react'

import * as icons from './icons'
import * as SC from './styled'

export type IconType = keyof typeof icons

export const Icons: { [key in IconType]: key } = Object.keys(icons).reduce(
  (r, k) => ({ ...r, [k]: k }),
  {} as { [key in IconType]: key }
)

export type IconProps = {
  className?: string
  icon: IconType
  color?: string
}

const Icon: FC<IconProps> = ({ className, icon, color = 'black' }) => {
  const { viewBox, id } = icons[icon]
  const symbolId = id?.replace('-usage', '') ?? ''

  return (
    <SC.Svg className={className} color={color} viewBox={viewBox}>
      <use xlinkHref={`#${symbolId}`} href={`#${symbolId}`} />
    </SC.Svg>
  )
}

export default memo(Icon)
