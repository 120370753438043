import * as controllers from '../Http/Controllers'

export type Route = {
  component: any
  path: string
  name: string
}

export type Routes = {
  root: Route
  home: Route
  legal: Route
}

const routes: Routes = {
  root: {
    component: controllers.HomepageController,
    path: '/',
    name: 'root',
  },
  home: {
    component: controllers.HomepageController,
    path: '/:lang',
    name: 'home',
  },
  legal: {
    component: controllers.LegalController,
    path: '/:lang/legal',
    name: 'legal',
  },
}

export default routes
