import React, { FC } from 'react'
import cx from 'classnames'

import * as SC from './styled'

export type MainLayoutProps = {
  className?: string
  header?: React.ReactNode
  footer?: React.ReactNode
  children?: React.ReactNode
}

const MainLayout: FC<MainLayoutProps> = ({ children, className, header = null, footer = null }) => {
  return (
    <SC.Layout className={cx('Layout', className)}>
      <>{header}</>
      <SC.Main>{children}</SC.Main>
      <>{footer}</>
    </SC.Layout>
  )
}

export default MainLayout
