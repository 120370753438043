import { SeoProps } from '../components/Seo'

import { useSiteMetadata } from './useSiteMetadata'

export const useSeo = ({ title, description, pathname }: any): SeoProps => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    image,
    siteUrl,
    twitterUsername,
  } = useSiteMetadata()
  return {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname || ``}`,
    image: image ? `${siteUrl}${image}` : undefined,
    twitter_account: twitterUsername,
  }
}
