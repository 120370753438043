import React, { ButtonHTMLAttributes, FC, useCallback } from 'react'

import { LinkProps } from '../Link'

import * as SC from './styled'

export type ActionButtonProps = LinkProps & {
  className?: string
  id?: string
  text?: string
  disabled?: boolean
  onClick?: (id?: string) => void
  children?: React.ReactNode
}

const ActionButton: FC<ActionButtonProps> = ({
  className,
  id,
  text,
  disabled = false,
  onClick = null,
  children,
  ...buttonProps
}) => {
  const handleOnClick = useCallback(() => {
    if (onClick) {
      onClick(id)
    }
  }, [id, onClick])

  return (
    <SC.ActionButton
      {...buttonProps}
      className={className}
      onClick={handleOnClick}
      disabled={disabled}
    >
      {text ? <SC.Text>{text}</SC.Text> : children}
    </SC.ActionButton>
  )
}

export default ActionButton
