import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

const styles = css`
  color: ${({ theme }) => theme.colors.pureBlack};
`

export const LinkA = styled.a`
  ${styles}
`

export const LinkGatsby = styled(Link)`
  ${styles}
`
