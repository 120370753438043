import styled, { keyframes } from 'styled-components'

import Link from '../Link'

const animation = keyframes`


  0% {
      transform: translateY(0)
  }

  49% {
      transform: translateY(-24px)
  }

  51% {
      transform: translateY(24px)
  }

  100% {
      transform: translateY(0)
  }
`

export const Text = styled.span`
  display: inline-block;
  ${({ theme }) => theme.textStyles.textSmall}
`

export const ActionButton = styled(Link)<{ disabled: boolean }>`
  background-color: ${({ disabled, theme }) =>
    disabled ? ' rgba(0, 0, 0, 0.1)' : theme.colors.pureWhite};
  color: ${({ disabled, theme }) => (disabled ? theme.colors.greyMedium : theme.colors.pureBlack)};
  padding: 1.2rem 1.6rem;
  border-radius: 4px;
  border: 1px solid
    ${({ disabled, theme }) => (disabled ? theme.colors.greyMedium : theme.colors.pureBlack)};
  text-transform: uppercase;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    ${Text} {
      animation-name: ${animation};
      animation-duration: 0.5s;
      animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }
`
